import * as types from '../action-types/config';

export const configViewMode = (mode) => {
  return function(dispatch){
    dispatch({
      type: types.CONFIG_VIEW_MODE,
      payload: mode
    });
  }
}

export const configDefaultPage = (page) => {
  return function(dispatch){
    dispatch({
      type: types.CONFIG_VIEW_MODE,
      payload: page
    });
  }
}
