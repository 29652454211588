import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './actions';
import ReactGA from "react-ga4";
import XcTicker from './pages/Ticker'

function App(props) {

  ReactGA.initialize("G-1QSRNZEBXR");
  ReactGA.send("pageview");

  function DefaultPage() {
    let page = '';

    switch(props.defaultPage) {
      case 'ticker':
        page = <XcTicker viewMode={props.viewMode} theme={props.tickerTheme} />
        break;
    }

    return page;
  }


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={DefaultPage()} />
        <Route path="/ticker" element={<XcTicker viewMode={props.viewMode} theme={props.tickerTheme} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default connect(
    state => ({
       defaultPage: state.Config.defaultPage,
       viewMode: state.Config.viewMode,
       tickerTheme: state.Ticker.theme
    }),
    dispatch => ({ actions: bindActionCreators(actions, dispatch)})
)(App);
