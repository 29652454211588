import { Maximize2, Minimize2, Menu } from '@geist-ui/icons'
import { Link, Image } from '@geist-ui/core'
import { useState, useEffect } from 'react'

export default function XcHeader(props) {

  const [openMenu, setOpenMenu] = useState(false);

  useEffect(()=> {
    if(openMenu) {
      let menu = document.querySelector('#menu').cloneNode(true);
      document.querySelector('#mobile-menu-container').appendChild(menu);
    }
  })

  return(
    <header id="xc-header" className={props.viewMode}>
       <h1 className="logo"><Image src="https://sjc1.vultrobjects.com/x-sure/x-crypto/x-crypto-logo.png" /></h1>
       <nav id="menu">
         <ul>
           <li><Link href="https://x-sure.co/x-sure-l1-real-time-crypto-ticker/?utm_source=x-crypto" target="_blank">X-SURE™ L1 Physical Crypto Ticker</Link></li>
           <li><Link href="https://chrome.google.com/webstore/detail/x-crypto/hmjoaalgapkjmnccfciciojahchjojlf" target="_blank">Chrome Extension</Link></li>
           <li><Link href="https://x-sure.co/discord" target="_blank">Join Discord</Link></li>
         </ul>
       </nav>


       <div className="view-mode">
         <span className={`toggle-menu ${props.viewMode}`} onClick={()=>setOpenMenu(true)}><Menu size={20} /></span>

         {props.viewMode === 'normal' ?
              <span onClick={()=>props.switchViewMode('fullscreen')}><Maximize2 size={20} /></span>
          :
              <span onClick={()=>props.switchViewMode('normal')}><Minimize2 size={20} /></span>
          }
       </div>

       {openMenu &&
         <div id="mobile-menu">
            <span className="close-menu" onClick={()=>setOpenMenu(false)}>&times;</span>
            <div id="mobile-menu-container"></div>
         </div>
       }
    </header>
  )
}
