import { MoreVertical } from '@geist-ui/icons'
import classnames from 'classnames'
import { useState } from 'react'

export default function XcSidebar ({viewMode, children}) {
  const [openMenu, setOpenMenu] = useState(false)

  function toggleMenu() {
    if(openMenu) {
      setOpenMenu(false)
    }else{
      setOpenMenu(true)
    }
  }

  return [
    <span key={'toggle-setting'} className="toggle-setting" onClick={toggleMenu}><MoreVertical size="20" /></span>,
    <aside key={'sidebar'} id="xs-sidebar" className={classnames(viewMode, openMenu && 'show')}>
      <button className="close-menu" onClick={()=>setOpenMenu(false)}>&times;</button>
      {children}
    </aside>
  ]
}
