import { Link} from '@geist-ui/core'
import { Twitter, Facebook, Youtube, Instagram, MessageSquare } from '@geist-ui/icons'
import classnames from 'classnames'

export default function XcFooter(props) {

  return(
    <footer id="xc-footer" className={classnames(props.viewMode, 'only-pc')}>
        <Link href="https://x-sure.co/feature-request?utm_source=x-crypto" target="_blank" className="feature-request"><MessageSquare size="14" />Feature Request</Link>
        <div className="copyright">
          Powered by <Link href="https://x-sure.co?utm_source=x-crypto" target="_blank">X-SURE Lab</Link>.
        </div>
        <div className="socials">
          <Link href="https://twitter.com/xsure_lab" target="_blank"><Twitter size="16" /></Link>
          <Link href="https://www.facebook.com/X-Sure-105425275421912" target="_blank"><Facebook size="16" /></Link>
          <Link href="https://instagram.com/xsure_lab" target="_blank"><Instagram size="16" /></Link>
          <Link href="https://www.youtube.com/channel/UC3NDFduNAuOFiXABWKlK59A" target="_blank"><Youtube size="16" /></Link>
        </div>
    </footer>
  )
}
