import * as ActionTypes from '../action-types/ticker';

const initState = {
    theme: 'default',
    tickerStyle: 'silver',
    tickerBg: {
      name: 'earth',
      format: 'mp4'
    },
    defaultTickerBackgrounds: [
      {name: "earth", format: "mp4"},
      {name: "default", format: "jpg"}
    ],
    defaultCurrency: 'BTC',
    tickerData: null,
    localCurrencyList: null,
    defaultLocalCurrency: null
}

export function tickerReducer(state = initState, action){

    if(!action.type){
        return state;
    }

    switch (action.type){
        case ActionTypes.TICKER_CHANGE_THEME: {

            return {
                ...state,
                theme: action.payload
            }
        }

        case ActionTypes.TICKER_L1_CHANGE_STYLE: {

            return {
                ...state,
                tickerStyle: action.payload
            }
        }

        case ActionTypes.TICKER_DEFAULT_CHANGE_STYLE: {

            return {
                ...state,
                tickerBg: action.payload
            }
        }

        case ActionTypes.TICKER_DEFAULT_BG: {

            return {
                ...state,
                defaultTickerBackgrounds: action.payload
            }
        }

        case ActionTypes.TICKER_DEFAULT_CURRENCY: {

            return {
                ...state,
                defaultCurrency: action.payload,
            }
        }

        case ActionTypes.TICKER_DEFAULT_LOCAL_CURRENCY: {

            return {
                ...state,
                defaultLocalCurrency: action.payload,
            }
        }

        case ActionTypes.TICKER_LOCAL_CURRENCY_LIST: {

            return {
                ...state,
                localCurrencyList: action.payload,
            }
        }

        case ActionTypes.TICKER_GET_DATA: {

            return {
                ...state,
                tickerData: action.payload,
            }
        }


        default: {
            return state;
        }
    }
}
