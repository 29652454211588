import ETH from '../l1/assets/eth.svg'
import BTC from '../l1/assets/btc.svg'
import BNB from '../l1/assets/bnb.svg'
import SOL from '../l1/assets/sol.svg'
import XRP from '../l1/assets/xrp.svg'
import DOT from '../l1/assets/dot.svg'
import ADA from '../l1/assets/ada.svg'
import DOGE from '../l1/assets/doge.svg'
import SHIB from '../l1/assets/shiba.svg'
import AVAX from '../l1/assets/avax.svg'
import LUNC from '../l1/assets/luna.svg'
import LON from '../l1/assets/lon.svg'

import { Image } from '@geist-ui/core'
import { TrendingUp, TrendingDown } from '@geist-ui/icons'

import { useState, useEffect } from 'react'

import { Socket } from 'phoenix'
import { getCoinOrder } from '../../../../lib/utils'
import classnames from 'classnames'
import XcClock from '../../../../components/Clock'

import './styles.scss'

export default function GridTheme(props) {
  const data = props.data;

  useEffect(()=> {
    let mounted = true;

    if (mounted) {
      reLayout(props.viewMode);
      window.addEventListener('resize', function() {
        reLayout(props.viewMode);
      }, true);
    }

    return () => (mounted = false);
  }, [props.viewMode]);

  function reLayout(viewMode) {
    let gridArea = document.querySelector('#grid-area');
    let gridFullscreen = document.querySelector('.grid-fullscreen');
    let gridItems = document.querySelectorAll('.grid-item');
    let columns = window.innerWidth <= 768 ? 2 : 4;

    for(let i = 0; i < gridItems.length; i++) {

      if(viewMode === 'fullscreen') {
        gridItems[i].style.width = gridFullscreen.offsetWidth / columns - 42 + 'px';
      }else if(props.viewMode === 'normal') {
        gridItems[i].style.width = gridArea.offsetWidth / columns - 42 + 'px';
      }
    }
  }

  function displayIconsAndFullname(currency) {
    let coin = '';
    switch(currency) {
      case 'ADA':
        coin = {
          name: 'Cardano',
          icon: ADA
        };
        break;
      case 'AVAX':
        coin = {
          name: 'Avalanche',
          icon: AVAX
        };
        break;
      case 'BNB':
        coin = {
          name: 'Binance',
          icon: BNB
        };
        break;
      case 'BTC':
        coin = {
          name: 'Bitcoin',
          icon: BTC
        };
        break;
      case 'DOGE':
        coin = {
          name: 'Dogecoin',
          icon: DOGE
        };
        break;
      case 'DOT':
        coin = {
          name: 'Polkadot',
          icon: DOT
        };
        break;
      case 'ETH':
        coin = {
          name: 'Ethereum',
          icon: ETH
        };
        break;
      case 'SHIB':
        coin = {
          name: 'Shiba Inu',
          icon: SHIB
        };
        break;
      case 'SOL':
        coin = {
          name: 'Solana',
          icon: SOL
        };
        break;
      case 'XRP':
        coin = {
          name: 'Ripple',
          icon: XRP
        };
        break;
      case 'LUNC':
        coin = {
          name: 'Terra Classic',
          icon: LUNC
        };
        break;
      case 'LON':
        coin = {
          name: 'Tokenlon',
          icon: LON
        };
        break;
    }

    return coin;
  }

  return (
    <div key="Grid" className="grid-theme">
      {data !== null && data.length ?
       <div className={classnames("grid-container", props.viewMode === 'fullscreen' && 'grid-fullscreen')}>
         <div id="grid-area">
             { data && data.map((item, i) => {
              return <div key={i} className={classnames("grid-item", item.symbol === 'SHIB' && "small-text")}>
                       <h2>${item.price}</h2>
                       <strong>
                         <Image className={classnames('coin')} src={displayIconsAndFullname(item.symbol).icon} /> <span>{item.symbol}</span>
                         <span className="trend">{item.change_status === 'down' ? <TrendingDown color="#f00" /> : <TrendingUp color="#6ce724" />}</span>
                       </strong>
                     </div>
             })}
         </div>
       </div>
       :
       <div className="loading">Loading the price data ...</div>
     }
    </div>
  )
}
