import * as types from '../action-types/ticker';
import { Socket } from 'phoenix'
import axios from 'axios'
import { resortArrayItems } from '../lib/utils'

export const tickerChangeTheme = (theme) => {
  return function(dispatch){
    dispatch({
      type: types.TICKER_CHANGE_THEME,
      payload: theme
    });
  }
}

export const L1TickerChangeStyle = (style) => {
  return function(dispatch){
    dispatch({
      type: types.TICKER_L1_CHANGE_STYLE,
      payload: style
    });
  }
}

export const DefaultTickerChangeStyle = (style) => {
  return function(dispatch){
    dispatch({
      type: types.TICKER_DEFAULT_CHANGE_STYLE,
      payload: style
    });
  }
}

export const DefaultTickerBackgrounds = () => {
  return function(dispatch) {
    const api = 'https://static.x-sure.co/x-crypto/ticker/default/bg.json';
    axios.get(api)
    .then(function (response) {
      dispatch({
        type: types.TICKER_DEFAULT_BG,
        payload: response.data
      });
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .then(function () {
      // always executed
    });
  }
}

export const tickerDefaultCurrency = (currency) => {
  return function(dispatch){
    dispatch({
      type: types.TICKER_DEFAULT_CURRENCY,
      payload: currency
    });
  }
}

export const tickerData2 = () => {
  return function(dispatch){
    const socket = new Socket('wss://ticker-service.x-sure.co/socket', { params: { token: '' } })
    socket.connect()
    socket.onError(() => console.log('there was an error with the connection!'))

    const channel = socket.channel('ticker:lobby', {})
    channel.on('ticker', res => {
      let prices = res.prices;

      dispatch({
        type: types.TICKER_GET_DATA,
        payload: prices
      });
    })

    channel.join()
    .receive('ok', resp => { console.log('Joined successfully', resp) })
    .receive('error', resp => { console.log('Unable to join', resp) })
  }
}

export const tickerData = () => {
  return function(dispatch){
    const api = 'https://ticker-service.x-sure.co/api/v1/ticker/price?symbol=BTC,ETH,BNB,ADA,DOGE,XRP,DOT,SOL,AVAX,SHIB,LUNA,LON';
    setInterval(() => {
      axios.get(api).then((res) => {
        let prices = res.data;

        dispatch({
          type: types.TICKER_GET_DATA,
          payload: prices
        });
      });
    }, 5000);
  }
}

export const getCurrencies = () => {
  return function(dispatch) {
    const api = 'https://openexchangerates.org/api/currencies.json';
    axios.get(api)
    .then(function (response) {
      let currencyList = Object.entries(response.data).map(([k, v]) => {
          return ({
            code: k,
            currency: v
          })
      });

      dispatch({
        type: types.TICKER_LOCAL_CURRENCY_LIST,
        payload: currencyList
      });
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .then(function () {
      // always executed
    });
  }
}

export const tickerDefaultLocalCurrency = (currency) => {
  return function(dispatch){
    dispatch({
      type: types.TICKER_DEFAULT_LOCAL_CURRENCY,
      payload: currency
    });
  }
}
