import ETH from './assets/eth.svg'
import BTC from './assets/btc.svg'
import BNB from './assets/bnb.svg'
import SOL from './assets/sol.svg'
import XRP from './assets/xrp.svg'
import DOT from './assets/dot.svg'
import ADA from './assets/ada.svg'
import DOGE from './assets/doge.svg'
import SHIB from './assets/shiba.svg'
import AVAX from './assets/avax.svg'
import LUNC from './assets/luna.svg'
import LON from './assets/lon.svg'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useState, useEffect } from 'react'
import { Image } from '@geist-ui/core'

import { Socket } from 'phoenix'
import { getCoinOrder } from '../../../../lib/utils'
import classnames from 'classnames'
import XcClock from '../../../../components/Clock'

// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

import './styles.scss'

export default function L1Theme(props) {

  const [swiper, setSwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const data = props.data;

  let appearance = {desktop: 'L1', mobile: 'L1-mb'}

  switch(props.tickerStyle) {
    case 'imtoken':
      appearance = {desktop: 'L1-imtoken', mobile: 'L1-imtoken-mb'};
      break;
  }

  useEffect(()=> {
    let mounted = true;

    if (mounted) {
      setActiveIndex(getCoinOrder(props.defaultCurrency))
    }

    return () => (mounted = false);
  }, []);

  function displayIconsAndFullname(currency) {
    let coin = '';
    switch(currency) {
      case 'ADA':
        coin = {
          name: 'Cardano',
          icon: ADA
        };
        break;
      case 'AVAX':
        coin = {
          name: 'Avalanche',
          icon: AVAX
        };
        break;
      case 'BNB':
        coin = {
          name: 'Binance',
          icon: BNB
        };
        break;
      case 'BTC':
        coin = {
          name: 'Bitcoin',
          icon: BTC
        };
        break;
      case 'DOGE':
        coin = {
          name: 'Dogecoin',
          icon: DOGE
        };
        break;
      case 'DOT':
        coin = {
          name: 'Polkadot',
          icon: DOT
        };
        break;
      case 'ETH':
        coin = {
          name: 'Ethereum',
          icon: ETH
        };
        break;
      case 'SHIB':
        coin = {
          name: 'Shiba Inu',
          icon: SHIB
        };
        break;
      case 'SOL':
        coin = {
          name: 'Solana',
          icon: SOL
        };
        break;
      case 'XRP':
        coin = {
          name: 'Ripple',
          icon: XRP
        };
        break;
      case 'LUNC':
        coin = {
          name: 'Terra Classic',
          icon: LUNC
        };
        break;
      case 'LON':
        coin = {
          name: 'Tokenlon',
          icon: LON
        }
    }

    return coin;
  }

  return [
    <div key="L1" className="L1-theme">
      <div className="L1">
        <div className="screen">
          {data && data.length ?
          <Swiper
            spaceBetween={0}
            slidesPerView={'auto'}
            className="ticker-slider"
            onSwiper={setSwiper}
            speed={300}
            initialSlide={getCoinOrder(props.defaultCurrency)}
            onSlideChange={(swiper)=>{
              setActiveIndex(swiper.activeIndex)
            }}
          >
            { data && data.map((item, i) => {
            let coin = displayIconsAndFullname(item.symbol);
             return <SwiperSlide key={i}>
                <div className="screen-page">
                  <header className="hd">
                    <div className="coin">
                      <img src={coin.icon} />
                      <p>
                        <span>{coin.name}</span>
                        <span>{item.symbol}</span>
                      </p>
                    </div>

                    <div className="clock">
                      <XcClock id={`l1-${i}`} />
                    </div>
                  </header>

                  <div className={classnames("data", item.change_status === 'down' ? 'downtrend' : 'uptrend')}>${item.price}</div>
                </div>
              </SwiperSlide>
            })}
          </Swiper>
          :
           <div className="loading">Loading the price data...</div>
          }
        </div>

        <div className="mockup">
          <LazyLoadImage className="only-pc" src={`https://sjc1.vultrobjects.com/x-sure/x-crypto/ticker/l1/${appearance.desktop}.png`} />
          <LazyLoadImage className="only-mb" src={`https://sjc1.vultrobjects.com/x-sure/x-crypto/ticker/l1/${appearance.mobile}.png`} />
        </div>
      </div>

      <div className="coins">
          <div className="coins-wrapper">
          {data && data.map((item, k) => {
              return <Image key={k} onClick={(e)=>swiper.slideTo(k)} className={classnames('coin-item', k===activeIndex && 'checked')} src={displayIconsAndFullname(item.symbol).icon} />
          })}
          </div>
      </div>

    </div>,
    <div key="L1-theme-bg" className="L1-theme-bg"><LazyLoadImage src="https://sjc1.vultrobjects.com/x-sure/x-crypto/ticker/l1/l1-theme-bg.jpg" /></div>
  ]
}
