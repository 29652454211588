import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history'
import { routerReducer, routerMiddleware} from 'react-router-redux';
import storage from 'redux-persist/lib/storage';
import {persistStore, persistCombineReducers} from 'redux-persist';
import rootReducers from '../reducers';
import { offline } from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults';


/**
 * global store instance.
 * @type {null}
 */
let storeInstance = null;

const rootPersistConfig = {
    key: 'root',
    storage,
    blacklist: [
      'Global',
      'Ticker',
    ]
};

export const history = createBrowserHistory();

let middlewares = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
    const logger = require('redux-logger');
    middlewares = [...middlewares, logger.default];

    /**
     * add debug console.

    let vConsole = require('vconsole') ;
    // eslint-disable-next-line
    let debugConsole = new vConsole();
     */
}


export const getStoreInstance = function(initialState) {
    if(storeInstance){
        return storeInstance;
    }else{
        const reducer = persistCombineReducers(rootPersistConfig, {...rootReducers, routing: routerReducer});
        const store = createStore(
            reducer,
            initialState,
            compose(
                applyMiddleware(...middlewares),
                offline(offlineConfig)
            )
        );
        persistStore(store);

        storeInstance = store;

        return storeInstance;
    }
}
