import ETH from './assets/eth.svg'
import BTC from './assets/btc.svg'
import BNB from './assets/bnb.svg'
import SOL from './assets/sol.svg'
import XRP from './assets/xrp.svg'
import DOT from './assets/dot.svg'
import ADA from './assets/ada.svg'
import DOGE from './assets/doge.svg'
import SHIB from './assets/shiba.svg'
import AVAX from './assets/avax.svg'
import LUNC from './assets/luna.svg'
import LON from './assets/lon.svg'

import { Image } from '@geist-ui/core'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useState, useEffect, useRef } from 'react'
import { Socket } from 'phoenix'
import { getCoinOrder, getLocalCurrencyValue } from '../../../../lib/utils'
import classnames from 'classnames'
import XcClock from '../../../../components/Clock'

// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { EffectCube } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import "swiper/css/effect-cube";

import './styles.scss'

export default function DefaultTheme(props) {
  const [swiper, setSwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [effect, setEffect] = useState(false);
  const data = props.data;

  useEffect(()=>{

    let mounted = true;

    if (mounted) {
      setActiveIndex(getCoinOrder(props.defaultCurrency))
      getLocal();
      let ua = window.navigator.userAgent.toLowerCase();
      if (ua.indexOf('safari') != -1) {
        if (ua.indexOf('chrome') > -1) {
          setEffect('cube');
        }else {
          setEffect('fade');
        }
      }
    }

    return () => (mounted = false);
  }, []);

  async function getLocal() {
      return await getLocalCurrencyValue('CNY', 1);
  }

  function displayIcons(currency) {
    let icon = '';
    switch(currency) {
      case 'ADA':
        icon = ADA;
        break;
      case 'AVAX':
        icon = AVAX;
        break;
      case 'BNB':
        icon = BNB;
        break;
      case 'BTC':
        icon = BTC;
        break;
      case 'DOGE':
        icon = DOGE;
        break;
      case 'DOT':
        icon = DOT;
        break;
      case 'ETH':
        icon = ETH;
        break;
      case 'SHIB':
        icon = SHIB;
        break;
      case 'LUNC':
        icon = LUNC;
        break;
      case 'SOL':
        icon = SOL;
        break;
      case 'XRP':
        icon = XRP;
        break;
      case 'LON':
        icon = LON;
        break;
    }

    return icon;
  }

  function tickerBg() {
    let bg = props.tickerBg;
    let url = '';

    if(bg.format == 'mp4') {
      url = `https://sjc1.vultrobjects.com/x-sure/x-crypto/ticker/default/${bg.name}/${bg.name}.mp4`;
      return <video key={bg.name} autoPlay muted loop preload="preload" playsInline poster={`https://sjc1.vultrobjects.com/x-sure/x-crypto/ticker/default/${bg.name}/poster.jpg`}>
                <source src={url} type="video/mp4" />
             </video>
    }else{
      url = `https://sjc1.vultrobjects.com/x-sure/x-crypto/ticker/default/${bg.name}/poster.${bg.format}`;
      return <LazyLoadImage src={url} />
    }
  }

  return (
    <div className="default-theme">
      <div className="data">
        {data !== null && data.length ?
          <Swiper
            spaceBetween={0}
            slidesPerView={'auto'}
            className="ticker-slider"
            modules={[EffectCube]}
            onSwiper={setSwiper}
            speed={500}
            initialSlide={getCoinOrder(props.defaultCurrency)}
            cubeEffect={{
              shadow: false,
              slideShadows: false,
              shadowOffset: 20,
              shadowScale: 0.94
            }}
            onSlideChange={(swiper)=>{
              setActiveIndex(swiper.activeIndex)
            }}
          >
            { data && data.map((item, i) => {
             return <SwiperSlide key={i}>
                       <div className={classnames("price", item.change_status === 'down' ? 'downtrend' : 'uptrend')}>
                         {item.symbol}: ${item.price}
                       </div>
                    </SwiperSlide>
            })}
          </Swiper>
        :
          <div className="loading">Loading the price data ...</div>
        }
        <time>LOCAL TIME: <XcClock id="default-clock" /></time>
      </div>

      <div className="coins">
        <div className="coins-wrapper">
        {data && data.map((item, k) => {
          return <Image key={k} onClick={(e)=>swiper.slideTo(k)} className={classnames('coin', k===activeIndex && 'checked')} src={displayIcons(item.symbol)} />
        })}
        </div>
      </div>

      <div className="bg">
        {tickerBg()}
      </div>
    </div>
  )
}
