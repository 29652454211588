import * as ActionTypes from '../action-types/config';

const initState = {
    viewMode: 'normal',
    defaultPage: 'ticker'
}

export function configReducer(state = initState, action){

    if(!action.type){
        return state;
    }

    switch (action.type){
        case ActionTypes.CONFIG_VIEW_MODE: {

            return {
                ...state,
                viewMode: action.payload,
            }
        }

        case ActionTypes.CONFIG_DEFAULT_PAGE: {

            return {
                ...state,
                defaultPage: action.payload,
            }
        }


        default: {
            return state;
        }
    }
}
