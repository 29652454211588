import { GeistProvider, CssBaseline, Themes } from '@geist-ui/core'
import XcHeader from './Header'
import XcFooter from './Footer'

const xcTheme = Themes.createFromLight(
  {
    type: 'lightTheme',
    palette: {
      success: "#46bb88",
      successLight: "#46bb88",
      background: "#fff",
      accents_1: "#f3f9f6",
      accents_2: "#d9f3e8"
    }
  }
)
const xcThemeDark = Themes.createFromDark(
  {
    type: 'darkTheme',
    palette: {
      success: "#46bb88",
      successLight: "#46bb88",
      background: "#1E1F27",
      accents_1: "#f3f9f6",
      accents_2: "#d9f3e8"
    }
  }
)

function XcLayout({ viewMode, switchViewMode, children }) {

  function changeViewMode(res) {
    switchViewMode(res);
  }

  return (
    <GeistProvider themes={[xcTheme, xcThemeDark]} themeType="darkTheme">
      <CssBaseline />
      <div className="xc-layout">
        <XcHeader viewMode={viewMode} switchViewMode={changeViewMode} />
        <div className="xc-content">
          <div className="xc-stage">
          { children }
          </div>
          <div style={{clear: 'both'}}></div>
        </div>
        <XcFooter viewMode={viewMode} />
      </div>
    </GeistProvider>
  );
}

export default XcLayout;
