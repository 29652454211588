import { useEffect } from 'react'

export default function XcClock(props) {
  var clock_now = 0;

  useEffect(() => {
    displayLocalTime()
  })

  function displayLocalTime() {
    var now = new Date();
    var hh = now.getHours() > 12 ? now.getHours() - 12 : now.getHours();
    var mm = now.getMinutes();
    var ss = now.getSeconds();
    var am_pm = now.getHours() >= 12 ? "PM" : "AM";
    hh = hh < 10 ? `0${hh}` : hh;
    mm = mm < 10 ? `0${mm}` : mm;
    ss = ss < 10 ? `0${ss}` : ss;

    if(document.getElementById('local-time-'+props.id) !== null) {
      document.getElementById('local-time-'+props.id).innerHTML = `<span class="h">${hh}</span><small>:</small><span class="m">${mm}</span><small>:</small><span class="s">${ss}</span>`;
    }
    if(document.getElementById('am-pm-'+props.id) !== null) {
      document.getElementById('am-pm-'+props.id).innerHTML = am_pm;
    }

    clock_now += 1;

    requestAnimationFrame(displayLocalTime)
  }

  return [
      <time key="local-time" id={`local-time-${props.id}`} className="time"></time>,
      <sub key="am-pm" id={`am-pm-${props.id}`} className="am-pm"></sub>
  ]
}
