import axios from 'axios'

export function resortArrayItems(arr, old_index, new_index) {
  if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
          arr.push(undefined);
      }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
}

export function getCoinOrder(coin) {
  let coins = ['BTC','ETH','BNB','XRP','ADA','SOL','AVAX','DOT','DOGE','SHIB','LON']
  for(let i = 0; i < coins.length; i++) {
    if(coins[i] === coin) {
      return i;
    }
  }
}

export const getLocalCurrencyValue = async (to, value) => {
  try {
    const { data } = await axios.get(`https://openexchangerates.org/api/latest.json?app_id=6afa19d9ceda42628272cf9fd30e6cb0`);
    let rates = data.rates;

    for(let k in rates) {
      if(k === to) {
        return rates[k] * value;
      }
    }
  } catch (error) {
    console.log(error);
  }
};
