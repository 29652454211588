import XcLayout from '../../components/Layout'
import XcSidebar from '../../components/Sidebar'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Settings, Layers, XCircle } from '@geist-ui/icons'
import { Link, Grid, Image, Text, Radio, Fieldset, Select } from '@geist-ui/core'
import Popup from 'reactjs-popup'
import { useState, useEffect, useRef } from 'react'
import classnames from 'classnames'

import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import * as actions from '../../actions';

import axios from 'axios';

/* Ticker Themes */
import L1Theme from './themes/l1'
import DefaultTheme from './themes/default'
import GridTheme from './themes/grid'

function XcTicker(props) {
  const [openTheme, setOpenTheme] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);

  useEffect(()=>{
    props.actions.tickerData();
    props.actions.getCurrencies();
    props.actions.DefaultTickerBackgrounds();
  },[])


  function switchTheme(res) {
    props.actions.tickerChangeTheme(res)
  }

  function switchL1Style(res) {
    props.actions.L1TickerChangeStyle(res)
  }

  function switchViewMode(res) {
    props.actions.configViewMode(res);
  }

  function switchBackground(res) {
    props.actions.DefaultTickerChangeStyle(res);
  }


  function themeDisplay() {
    let theme = '';

    switch(props.tickerTheme) {
      case 'default':
        theme = <DefaultTheme defaultCurrency={props.defaultCurrency} tickerBg={props.tickerBg} tickerTheme={props.tickerTheme} data={props.tickerData} defaultLocalCurrency={props.defaultLocalCurrency} />
        break;

      case 'l1':
        theme = <L1Theme defaultCurrency={props.defaultCurrency} tickerTheme={props.tickerTheme} tickerStyle={props.tickerStyle} data={props.tickerData} defaultLocalCurrency={props.defaultLocalCurrency} />
        break;

      case 'grid':
        theme = <GridTheme defaultCurrency={props.defaultCurrency} tickerTheme={props.tickerTheme} data={props.tickerData} viewMode={props.viewMode} defaultLocalCurrency={props.defaultLocalCurrency} />
        break;
    }

    return theme;
  }

  return (
    <XcLayout viewMode={props.viewMode} switchViewMode={switchViewMode}>
        <XcSidebar viewMode={props.viewMode}>
          <ul className="xs-sidebar__menu">
            <li>
              <span className="clickable" onClick={()=>setOpenSettings(true)}><Settings size="16" />Settings</span>
              <Popup
                open={openSettings}
                modal
                closeOnDocumentClick={false}
                position="center center">
                  <XCircle size="30" className="close-popup" onClick={()=>setOpenSettings(false)} />
                  <Text h3 className="popup-title">Ticker Settings</Text>
                    <Text>Default Cryptocurrency Display:</Text>
                    <Radio.Group value={props.defaultCurrency} useRow onChange={(value)=>props.actions.tickerDefaultCurrency(value)}>
                      <Radio checked value="BTC">BTC</Radio>
                      <Radio value="ETH">ETH</Radio>
                      <Radio value="BNB">BNB</Radio>
                      <Radio value="SOL">SOL</Radio>
                      <Radio value="ADA">ADA</Radio>
                      <Radio value="XRP">XRP</Radio>
                      <Radio value="DOT">DOT</Radio>
                      <Radio value="DOGE">DOGE</Radio>
                      <Radio value="SHIB">SHIB</Radio>
                      <Radio value="AVAX">AVAX</Radio>
                      <Radio value="LUNC">LUNC</Radio>
                      <Radio value="LON">LON</Radio>
                    </Radio.Group>
              </Popup>
            </li>
            <li><span className="clickable" onClick={()=>setOpenTheme(true)}><Layers size="16" />Themes</span>
              <Popup
                open={openTheme}
                modal
                closeOnDocumentClick={false}
                position="center center">
                  <XCircle size="30" className="close-popup" onClick={()=>setOpenTheme(false)} />
                  <Text h3 className="popup-title">Ticker Themes</Text>
                  <Grid.Container gap={2}>
                    <Grid xs={24} md={12}>
                      <Image className={classnames('image-radio', props.tickerTheme === 'default' && 'checked')} onClick={()=>switchTheme('default')} src="https://sjc1.vultrobjects.com/x-sure/x-crypto/ticker/default/screenshot.jpg" />
                    </Grid>
                    <Grid xs={24} md={12}>
                      <Image className={classnames('image-radio', props.tickerTheme === 'l1' && 'checked')} onClick={()=>switchTheme('l1')} src="https://sjc1.vultrobjects.com/x-sure/x-crypto/ticker/l1/screenshot.jpg" />
                    </Grid>
                    <Grid xs={24} md={12}>
                      <Image className={classnames('image-radio', props.tickerTheme === 'grid' && 'checked')} onClick={()=>switchTheme('grid')} src="https://sjc1.vultrobjects.com/x-sure/x-crypto/ticker/grid/screenshot.jpg" />
                    </Grid>
                  </Grid.Container>
              </Popup>
            </li>
          </ul>

          {props.tickerTheme === 'l1' &&
            <div className="l1-styles">
              <strong>L1 Ticker Appearance</strong>
              <ul>
                <li className={classnames(props.tickerStyle === 'silver' && 'checked')} onClick={()=>switchL1Style('silver')}>Silver Classic</li>
                <li className={classnames(props.tickerStyle === 'imtoken' && 'checked')} onClick={()=>switchL1Style('imtoken')}>imToken Edition</li>
              </ul>
            </div>
          }

          {props.tickerTheme === 'default' &&
            <div className="default-styles">
              <strong>Default Ticker Background</strong>

              <div className="bg-options">
                <Grid.Container gap={2}>
                  {
                    props.defaultTickerBackgrounds && props.defaultTickerBackgrounds.map((item, i)=>{
                      return <Grid key={i} xs={12} md={6}>
                        <Image className={classnames('image-radio', props.tickerBg.name === item.name && 'checked')} onClick={()=>switchBackground(item)} src={`https://sjc1.vultrobjects.com/x-sure/x-crypto/ticker/default/${item.name}/poster.jpg`} />
                      </Grid>
                    })
                  }
                </Grid.Container>
              </div>
            </div>
          }
          <a className="ph-badge" href="https://www.producthunt.com/posts/x-crypto?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-x&#0045;crypto" target="_blank"><Image src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=353710&theme=dark" alt="X&#0045;Crypto - Simple&#0032;&#0038;&#0032;stylish&#0032;web&#0032;crypto&#0032;ticker | Product Hunt" style={{width: '200px'}} /></a>
        </XcSidebar>

        <div className="main">
          {themeDisplay()}
        </div>

        <style>{`
          .currencies-options .checkbox {
            margin-right: 20px;
          }
        `}</style>
    </XcLayout>
  );
}

export default connect(
  state => ({
    viewMode: state.Config.viewMode,
    tickerTheme: state.Ticker.theme,
    tickerStyle: state.Ticker.tickerStyle,
    tickerBg: state.Ticker.tickerBg,
    defaultCurrency: state.Ticker.defaultCurrency,
    tickerData: state.Ticker.tickerData,
    localCurrencyList: state.Ticker.localCurrencyList,
    defaultLocalCurrency: state.Ticker.defaultLocalCurrency,
    defaultTickerBackgrounds: state.Ticker.defaultTickerBackgrounds
  }),
  dispatch => ({actions: bindActionCreators(actions, dispatch)})
)(XcTicker);
